import { t } from 'i18next';
import moment from 'moment';
import {
  isValidIBAN,
  isValidBIC,
  isValidEmail,
  isValidPhone,
  isStrictlyPositiveNumber,
  isPositiveNumber,
  isValidPassword,
  optional,
  isDefined,
  isValidVatNumber,
  isPercentage,
  isValidArray,
  isValidLeasingDuration,
  isString,
} from './validators';

export const ibanValidation = (value) => !optional(isValidIBAN, value) && `${t('This is not a valid IBAN.')} \n${t('An example of a valid IBAN format is')}: \nSE4550000000058398257466`;
export const bicValidation = (value) => !optional(isValidBIC, value) && `${t('This is not a valid BIC number.')} \n${t('An example of a valid BIC format is')}: \nAGRKMTMTXXX`;
export const emailValidation = (value) => !optional(isValidEmail, value) && `${t('This is not a valid email address.')} \n${t('Follow the format')} username@domain.com.`;
export const phoneValidation = (value) => !optional(isValidPhone, value) && `${t('This is not a valid phone number')}.\n ${t('Please use country calling code')},\ne.g. ${t('the format from Sweden is')} +46 701 234 567.`;
export const strictlyPositiveNumberValidation = (value) => !optional(isStrictlyPositiveNumber, value) && t('This field should be a strictly positive number');
export const positiveNumberValidation = (value) => !optional(isPositiveNumber, value) && t('This field should be a positive number');
export const passwordValidation = (value) => !optional(isValidPassword, value) && t('This is not a valid password');
export const vatNumberValidation = (value) => !optional(isValidVatNumber, value) && `${t('This is not a valid vat number.')}\n ${t('Please start with country code')} e.g.: SE999999999901`;
export const percentageValidation = (value) => !optional(isPercentage, value) && t('This is not a valid percentage');
export const required = (value) => !isDefined(value) && t('This field is required');
export const feeLengthValidation = (value) => isValidArray(value) && t('This is not valid fee settings');
export const increaseTermsDurationSteps = (value) => !isValidLeasingDuration(value) && t('This is not a valid duration');
export const isTodayOrFutureDate = (value) => !moment(value).isSameOrAfter(moment(), 'day') && t('This date is in the past');
export const isTodayOrPastDate = (value) => !moment(value).isSameOrBefore(moment(), 'day') && t('This date is in the future');
export const isValidOrderReferenceNumber = (value) => !(isString(value) && value.length <= 50) && `${t('This is not a valid reference number')}. \n ${t('The maximum length is 50 characters')}`;
