import React, { useCallback } from 'react';

import {
  useRecordContext,
  usePermissions,
  useNotify,
} from 'react-admin';

import { Typography } from '@mui/material';

import useCustomRpc from '../hooks/useCustomRpc';

import hasUserPermissions from '../../utils/hasUserPermissions';

import { useConstantContext } from '../ConstantsContext';
import ModalButton from '../designSystem/ModalButton';
import DropDownMenu from '../../utils/DropDownMenu';

const MarkAsUnidentifiedButton = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const { constants } = useConstantContext();
  const authenticationData = usePermissions();
  const {
    id,
    type,
    amount,
    canRevert,
    origin,
  } = record;
  const { userActions, transactionTypes, transactionOrigins } = constants;
  const { userAllowedPermissions } = authenticationData.permissions || {};

  const {
    mutate: revertPayment,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'put',
    path: `transaction/${id}/mark-as-unidentified`,
    shouldRefresh: true,
    errorMessage: 'Could not revert payment',
    onSuccess: () => {
      notify('Payment has been marked as unidentified');
    },
  });

  const onSubmit = useCallback(() => {
    revertPayment();
    return true;
  }, [revertPayment]);

  const canRevertPayment = hasUserPermissions(
    userAllowedPermissions,
    userActions.MANAGE_PAYMENTS,
  );

  const isUserPayment = (type === transactionTypes.USER_PAYMENT)
  || (type === transactionTypes.INTERNAL && origin.type === transactionOrigins.PAYIN);

  const shouldBeDisabled = isLoading
    || !canRevert
    || !canRevertPayment
    || !isUserPayment
    || amount <= 0;

  if (shouldBeDisabled) return null;

  return (
    <DropDownMenu>
      <ModalButton
        modalTitle="Mark as unidentified"
        openButtonLabel="Mark payment as unidentified"
        actionLabel="Mark payment as unidentified"
        variant="subAction"
        onClick={onSubmit}
        withForm
        width="47.5rem"
      >
        <Typography>
          This payment will be marked as unidentified and will be available for further processing.
          This action cannot be undone.
        </Typography>
      </ModalButton>
    </DropDownMenu>
  );
};

export default MarkAsUnidentifiedButton;
