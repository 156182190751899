import React from 'react';
import {
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Toolbar from './Toolbar';

import EntityOrdersTab from '../sharedTabs/EntityOrdersTab';
import EntityHistoryTab from '../sharedTabs/EntityHistoryTab';

import { useConstantContext } from '../../ConstantsContext';
import TabbedForm from '../../designSystem/react-admin/TabbedForm';
import Edit from '../../designSystem/react-admin/Edit';
import TitleH1 from '../../designSystem/TitleH1';

import SummaryTab from './tabs/SummaryTab';
import SummaryTabNew from './tabs/SummaryTabNew';
import DetailsTab from './tabs/DetailsTab';
import FinancingKycTab from './tabs/FinancingKycTab';

import useHasUserPermissions from '../../hooks/useHasUserPermissions';
import useFeatureFlags from '../../hooks/useFeatureFlags';

export const getCustomerEditTitle = (record) => (record && record.mainAddress ? record.mainAddress.name : '');

const Title = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  return <TitleH1 title={`${t('Customer')}: ${getCustomerEditTitle(record)}`} />;
};

const CustomerEdit = () => {
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const [featureFlags] = useFeatureFlags();

  const canViewCustomer = useHasUserPermissions(
    constants,
    userActions.VIEW_CUSTOMERS,
  );

  // Redirect instead
  if (!canViewCustomer) return null;

  return (
    <Edit
      title={<Title />}
      appLocation="home.entity-v2/customer.edit"
    >
      <TabbedForm
        sanitizeEmptyValues
        toolbar={<Toolbar />}
      >
        { featureFlags.enableAnalyticsAndRedesign
        && <FinancingKycTab />}
        { featureFlags.enableAnalyticsAndRedesign
          ? <SummaryTabNew />
          : <SummaryTab />}
        <DetailsTab />
        <EntityOrdersTab queryTarget="debtorId" isCustomerView />
        <EntityHistoryTab />
      </TabbedForm>
    </Edit>
  );
};

export default CustomerEdit;
