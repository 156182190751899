import React from 'react';

import {
  TextField,
  ReferenceField,
  DateField,
  FunctionField,
  useResourceContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import FieldsGroup from '../utils/FieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import UpdateKycButton from './UpdateKycButton';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import CurrencyField from '../utils/CurrencyField';
import { useConstantContext } from '../ConstantsContext';

const renderCreditScore = (record) => {
  if (!record) return '';
  const { creditScore, creditScoreProvider } = record.kyc || {};
  if (!creditScore || !creditScoreProvider) return '-';
  return `${creditScore} (${creditScoreProvider})`;
};
const renderPaymentScore = (record) => {
  if (!record) return '';
  const { paymentScore, paymentScoreProvider } = record.kyc || {};
  if (!paymentScore || !paymentScoreProvider) return '-';
  return `${paymentScore} (${paymentScoreProvider})`;
};

const KycGroup = () => {
  const resource = useResourceContext();
  const { hasFactoring, isFinancer } = useUserPermissionsAndRoles();
  const { t } = useTranslation();
  const { clientInfo } = useConstantContext();

  if (!hasFactoring || !isFinancer) return null;

  return (
    <ReferenceField
      label={false}
      source="id"
      reference="entity-v2/kyc-info"
      link={false}
    >
      <FieldsGroup
        title={t('KYC information')}
        actions={<UpdateKycButton entityResource={resource} />}
      >
        <Grid container>
          <Grid item>
            <SimpleShowLabeled>
              <DateField showTime source="lastKycDoneAt" label={t('Last KYC date')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>

          <Grid item lg={3}>
            <SimpleShowLabeled>
              <FunctionField render={renderCreditScore} label={t('Credit score')} />
            </SimpleShowLabeled>
          </Grid>

          <Grid item lg={3}>
            <SimpleShowLabeled>
              <FunctionField render={renderPaymentScore} label={t('Payment score')} />
            </SimpleShowLabeled>
          </Grid>

          <Grid item lg={3}>
            <SimpleShowLabeled>
              <TextField source="kyc.financerScore" label={t('Financer score')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>

          <Grid item lg={3}>
            <SimpleShowLabeled>
              <CurrencyField source="kyc.expectedVolume" label={t('Expected monthly order volume')} emptyText="-" currency={clientInfo.referenceCurrency} />
            </SimpleShowLabeled>
          </Grid>

        </Grid>
      </FieldsGroup>
    </ReferenceField>
  );
};

export default KycGroup;
